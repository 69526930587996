import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Button, Row, Col, Alert,Toast } from 'react-bootstrap'
import { cap, pw } from '../params'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/nanda.mp3'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#EDA509'
let mainfont = '#EDA509'
let black = 'rgb(38,38,38)'
let id = 'mega-mardame'
let inisial_co = 'Mardame'
let inisial_ce = 'Mega'
let lengkap_co = <>Mardame Parningotan <br /> Parulian Sormin</>
let lengkap_ce = <>Mega Natalia <br />Sihombing</>
let bapak_co = 'Bpk. T. Sormin, SE'
let ibu_co = 'Ibu E. Hutabarat'
let bapak_ce = "Bpk. Drs. S. Sihombing "
let ibu_ce = "Ibu R. Lumbangaol"
let ig_co = "mardamepps"
let ig_ce = "natalimega"


let modal = gambar(pw(id, "modal.jfif"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720')
let openlogo = pw(id, "logo.png")

let gmaps = "https://g.page/rumatatavilla?share"
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.0827776948486!2d106.75703481476933!3d-6.252823795474013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f10e26f1ce33%3A0xbe192629e4eb27dc!2sRUMATATA%3B%20your%20vacation%20home!5e0!3m2!1sid!2sid!4v1609955504984!5m2!1sid!2sid"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MTR1NjFwNjk3ODNsaGt1ZGE0OHFxdDVucjAgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'

let slide = [
    "166C4B82-951D-498D-BF4E-622AD7357899.jpg",
    "44ACEFDE-2E08-4081-B144-4F82A6D339C5.jpg",
    "98BC17A9-6664-4892-8A8F-12FCF1DFE77E.jpg",
    "IMG_3240.jpg",
    "IMG_3255.jpg",
    "IMG_3260.jpg",
    "IMG_3273.jpg",
]
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2,
            show:false,
            show1:false
        }
    }
    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date("01/30/2021").getTime();
        // Update the count down every 1 second
        var x = setInterval(() => {
            // Get today's date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);
    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, show1,sesi,show,showgift, } = this.state
        let slide = ["IMGL4998_compressed.jpg",
            "IMGL5088_compressed.jpg",
            "IMGL5180_compressed.jpg",
            "IMGL5193_compressed.jpg",
            "IMGL5456_compressed.jpg",
            "IMGL5528.jpg",
            "AKA01232_compressed.jpg",
            "AKA01288_compressed.jpg",
        ]
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undang.in/${id}`}
                />

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url(${gambar(pw(id, "modal.jfif"), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1080&h=720')})`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={pw(id, "logo.png")} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-md-3`} style={{ marginTop: '0' }}>
                                        <br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 btn button roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: "white" }}>
                                    Open Invitation
                  </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>
                                        <p className="fs24 text-center cblack px-3">
                                            <span style={{ fontSize: '18px' }}>
                                                <b>
                                                    Kolose 3 : 14 <br />
                                            Dan di atas semuanya itu : kenakanlah kasih , sebagai pengikat yang mempersatukan dan menyempurnakan
                                            </b>
                                            </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} md={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: mainfont }}>
                                            Pernikahan  {inisial_co} &  {inisial_ce}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={pw(id, "co.jpg")} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{ fontSize: '36px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari : </b><br />
                                                        {bapak_co}

                                                        <br />
                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>


                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }}
                                                        style={{ width: '35px', height: '35px' }}
                                                    />

                                                </Item>
                                            </div>
                                        </div>

                                        <div className='  col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={pw(id, "ce.jpg")} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '36px', fontFamily: "'Marck Script', cursive",
                                                        color: mainfont
                                                    }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari: </b><br />
                                                        {bapak_ce}
                                                        <br />
                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>


                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }}
                                                        style={{ width: '35px', height: '35px' }}
                                                    />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang akan dilaksanakan pada:
                    </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">30</span> JAN 2021
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-md-4" style={{ color: mainfont }}>
                                            <b>Pemberkatan</b><br />
                                            <span className="cblack fs16">
                                                08.00 WIB - 10.00 WIB
                                            <br />
                                            Gereja HKBP Rawamangun<br />
                                                <i>Jalan Balai Pustaka No.33 Rawamangun,<br /> Jakarta Timur</i>
                                            </span>
                                        </p>
                                        <p className="px-3 d-none d-md-block" style={{ color: mainfont, fontSize: '72px' }}>
                                            \
                    </p>
                                        <div className="col-8 d-md-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-md-0 col-md-4" style={{ color: mainfont }}>
                                            <b>Resepsi</b><br />
                                            <span className="cblack fs16">
                                                11.00 - 14.00 WIB
                                            <br />
                                            Maria Convention Hall<br />
                                                <i>Jalan Perintis Kemerdekaan 7-9 Pulo Gadung,<br /> Jakarta Timur</i>
                                            </span>

                                        </p>
                                    </Item>
                                    <Item>
                                                <div className='p-3 mb-2 fs16' style={{ color: 'black', fontSize: '14px' }}>
                                                    Tanpa mengurangi rasa hormat, dikarenakan situasi pandemi saat ini, tidak memungkinkan bagi kami untuk mengundang Bapak/Ibu/Saudara/i untuk menghadiri pernikahan putra-putri kami serta memberikan doa restu secara langsung.<br />
Kami mengharapkan jarak tidak menjadi penghalang Bapak/Ibu/Saudara/i untuk tetap mengikuti prosesi pelaksanaan pernikahan putra-putri kami secara virtual. Untuk itu Bapak/Ibu/Saudara/i dapat menyaksikannya melalui tautan dibawah ini.
                                            </div>
                                            </Item>
                                            <Button style={{ backgroundColor: cmain }} className="mb-3" onClick={() => {
                                                window.location.href = 'https://youtube.com/c/HKBPRAWAMANGUN'
                                            }}>
                                                Live Streaming Pemberkatan
                                            </Button>

                                    <Item>
                                        <Col xs={10} md={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open("https://goo.gl/maps/yZwPiQ6pStu15EEJ9")
                                            }}
                                            className="p-2 mx-md-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Google Maps</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=NnA4azFzdjdvM2Z0NjNuZzY4ZTc2aTR0a2MgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                                            xs={10} md={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Google Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Container id='sectiongold57'>
                                    <div className='py-3'>

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hari
                                </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Jam
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mnt
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Dtk
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>
                                <Container className="text-center dinny">
                                    <Item>
                                        <Col className="py-3 px-md-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                                            <h1 className="fs30"
                                                style={{

                                                    fontFamily: "'Marck Script', cursive",
                                                    color: mainfont
                                                }}>
                                                Protokol Kesehatan
                          </h1>
                                            <Item>
                                                <Col xs={12}>
                                                    <p className="cblack text-center w-100 fs16">
                                                        Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                                                </Col>
                                                <Col xs={6} md={4}>
                                                    <img src={mask} className="w-100 img-fluid p-md-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Gunakan Masker
                            </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} md={4}>
                                                    <img src={distance} className="w-100 img-fluid p-md-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Jaga Jarak
                            </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} md={4}>
                                                    <img src={salaman} className="w-100 img-fluid p-md-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Tanpa Bersentuhan
                      </p>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </Col>
                                    </Item>

                                    <Item>
                                        <Col>
                                        </Col>

                                    </Item>

                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4'>
                                                            <i>
                                                                Let the bough break, let it come down crashing <br />
Let the sun fade out to a dark sky<br />
I can’t say I’d even notice it was absent<br />
Cause I could live by the light in your eyes<br />
                                                            </i>
                                                        </p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: mainfont
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1" style={{color:mainfont}}>Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"
                                    style={{color:mainfont}}
                                    >Tidak Hadir</label>
                                  </div>

                                </div>
                              </div>
                            </Item>

                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container className="py-3" fluid >
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ showgift: !showgift })}
                                            xs={10} md={4}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Send Gift (click here)</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                {showgift ? (
                                    <Container
                                        className={`text-center mt-3 aulia-wedding py-3 px-3`} style={{backgroundColor:cmain}} >


                                        <h1 style={{ color: 'white' }} className="w-100 text-center">
                                            Wedding Gift
                                     </h1>
                                     
                                    <p className="text-center w-100" style={{ color: 'white' }}>
                                    BCA - 0661301251   <br />
                                     an. Mega Natalia
                                         </p>
                                   <Item>
                                     <Toast onClose={() => this.setState({ show1: false })} show={this.state.show1} delay={3000} autohide >
                                       <Toast.Body>Copied!</Toast.Body>
                                     </Toast>
                                   </Item>
                                   <Item>
                                     <CopyToClipboard text="0661301251 ">
                                       <Col xs={8} className='btn button mb-5 mt-1'
                                         onClick={(e) => {
                                           this.setState({ show1: true })
                                         }}>
                                         Salin No. Rekening
                                         </Col>
                                     </CopyToClipboard>
                                   </Item>

                                   <p className="text-center w-100" style={{ color: 'white' }}>
                                    Mandiri - 1130010957763   <br />
                                     an. Mardame Parningotan Parulian
                                         </p>
                                   <Item>
                                     <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                                       <Toast.Body>Copied!</Toast.Body>
                                     </Toast>
                                   </Item>
                                   <Item>
                                     <CopyToClipboard text="1130010957763 ">
                                       <Col xs={8} className='btn button mb-5 mt-1'
                                         onClick={(e) => {
                                           this.setState({ show: true })
                                         }}>
                                         Salin No. Rekening
                                         </Col>
                                     </CopyToClipboard>
                                   </Item>
                                   
                                         

                                    </Container>
                                ) : false}


                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

